import { memo } from 'react';
import { FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FilterAccordion from '../FilterAccordion';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
const DateFromToFilter = ({ disabled = false }) => {
  const { t } = useTranslation();

  return (
    <FilterAccordion
      // title={t(`tasks.filter.date.custom`)}
      title='Sana'
      code='home-repair-type'
    >
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <DateRangeFilter />
      </FormControl>
    </FilterAccordion>
  );
};
export default memo(DateFromToFilter);
