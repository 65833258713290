/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, CircularProgress, Grid } from '@mui/material';
import { t } from 'i18next';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

function TemplateCard({ template }) {
  const textDiv = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialText, setInitialText] = useState(null);
  const sendNotification = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/admin/sms/keys/{template?.id}', template?.id],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/sms/keys/${template?.id}`
      );
      return response?.data?.data;
    },
    // enabled: !hasError && isEditing,
    enabled: !hasError,
    // enabled: false,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    if (data?.length && template?.template && textDiv.current) {
      let innerText = template?.template;

      for (let x = 0; x < data?.length; x++) {
        const { key } = data[x];
        const span = `<span
            contenteditable='false'
            class='rounded-md px-1 bg-base-color-light-f-15 text-sm whitespace-nowrap text-base-color-light'
          >
            ${key}
            <i class='bi bi-x cursor-pointer ml-1 remove-key-element' onclick="this.parentNode.remove()"></i>
          </span>`;

        if (innerText.includes(key)) {
          innerText = innerText?.replaceAll(key, span);
        }
      }
      textDiv.current.innerHTML = '';
      setInitialText({ __html: innerText || '' });
    }
  }, [template?.template, data?.length]);

  const onSave = async () => {
    setIsEditing(false);
    const { innerText } = textDiv.current;
    if (innerText?.trim() !== template?.template)
      try {
        const response = await axiosPrivate.post(
          `/admin/sms/templateupdate/${template?.id}`,
          JSON.stringify({ template: innerText?.trim() }),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (response.data && response.data.status) {
          sendNotification({
            msg: t('settings.sms.alerts.templateSuccess'),
            variant: 'success',
          });
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      }
  };

  function insertTextAtCaret(text) {
    const span = document.createElement('span');
    const i = document.createElement('i');
    span.setAttribute(
      'class',
      'rounded-md px-1 bg-base-color-light-f-15 text-sm text-base-color-light whitespace-nowrap'
    );
    span.setAttribute('contenteditable', 'false');

    i.onclick = () => {
      span?.remove();
    };
    i.setAttribute('class', 'bi bi-x cursor-pointer ml-1 remove-key-element');
    span.innerText = text;
    span.appendChild(i);
    var sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(span);
        sel.collapseToEnd();
      }
    }
  }

  return (
    <Grid item={true} xs={1}>
      <div className='p-3 my-shadow-1 rounded-lg'>
        <div className='flex items-center justify-between'>
          <div className='text-md text-black font-semibold  py-2 px-4 non'>
            {template.name}
          </div>
          {!isEditing && (
            <Button
              className='!text-base-color-light cursor-pointer !text-md !px-2'
              startIcon={<i className='bi bi-pencil !text-md' />}
              size='small'
              onClick={(e) => {
                textDiv.current.focus();
                setIsEditing(true);
                e.preventDefault();
              }}
            >
              <span>{t('common.button.edit')}</span>
            </Button>
          )}
        </div>
        <div className='text-md text-black font-normal'>
          <div
            ref={textDiv}
            className={`settings-sms-template w-full text-sm py-4 px-4 border rounded-sm outline-none ${
              isEditing ? 'editing' : ''
            } ${!isEditing && 'border-transparent'}`}
            contentEditable={isEditing?.toString()}
            dangerouslySetInnerHTML={initialText}
          />
          {/* <div
            ref={textDiv}
            className={`settings-sms-template w-full text-sm py-4 px-4 border rounded-sm outline-none ${
              !isEditing && 'border-transparent'
            }`}
            contentEditable={isEditing?.toString()}
            onChange={(e) => console.log(e)}
          >
            {template.template}
            <span
              contentEditable='false'
              className='rounded-md px-1 bg-base-color-light-f-15 text-sm whitespace-nowrap text-base-color-light'
              onClick={(e) => e?.target?.parentNode?.remove()}
            >
              $fddf
              <i className='bi bi-x cursor-pointer ml-1' />
            </span>
          </div> */}
        </div>
        {isEditing ? (
          isLoading || isFetching ? (
            <div className='circular-progress-box py-5'>
              <CircularProgress size={35} />
            </div>
          ) : (
            <div className='mt-4 flex justify-between'>
              <div className='flex gap-2 flex-wrap'>
                {data?.map((temp) => (
                  <Button
                    key={temp?.id}
                    color='primary'
                    variant='contained'
                    className='!py-1'
                    size='small'
                    onClick={(e) => {
                      textDiv.current.focus();
                      insertTextAtCaret(temp.key);
                      e.preventDefault();
                    }}
                  >
                    {temp?.name}
                  </Button>
                ))}
              </div>
              {isEditing && (
                <div className='flex items-start'>
                  <Button
                    className='!text-base-color-light !py-0 !px-2'
                    startIcon={<i className='bi bi-cloud-check' />}
                    size='small'
                    onClick={onSave}
                  >
                    {t('common.button.save')}
                  </Button>
                </div>
              )}
            </div>
          )
        ) : null}
      </div>
    </Grid>
  );
}

export default memo(TemplateCard);
