import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DateFromToFilter from './items/dateFromToFilter';
import ContractStatusFilter from './items/ContractStatusFilter';

import { contractStatusTypeSelectOptions } from 'shared/selectOptionsList';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import PaymentTypeFilter from './items/PaymentTypeFilter';
import ObjectsTypeFilter from './items/ObjectsTypeFilter';
// /dictionary/types
const PaymentFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
      <div className='dashboard-filter-drawer-wrapper'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateFromToFilter disabled={isLoading} />
        <ContractStatusFilter
          options={contractStatusTypeSelectOptions}
          disabled={isLoading}
          queryName='contract_status'
        />
        <ContractStatusFilter
          options={[
            { code: 'income', label: 'Kirim' },
            { code: 'outcome', label: 'Chiqim' },
          ]}
          disabled={isLoading}
          queryName='income_outcome'
          label='Kirim Chiqim'
        />
        <PaymentTypeFilter disabled={isLoading} />
        <ObjectsTypeFilter disabled={isLoading} />
        <ContractStatusFilter
          options={currencyTypeSelectOptions}
          disabled={isLoading}
          queryName='valute'
          label='Valuta'
        />
      </div>
    </Drawer>
  );
};
export default PaymentFilterDrawer;
